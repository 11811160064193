<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Left Text-->
            <b-col lg="7" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="
                        w-100
                        d-lg-flex
                        align-items-center
                        justify-content-center
                        px-5
                    "
                >
                    <b-img
                        fluid
                        :src="'/illustrations/register-bg.svg'"
                        alt="Register"
                        style="width: 65%;"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Register-->
            <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        Create Enston Ads Account
                    </b-card-title>

                    <!-- form -->
                    <validation-observer ref="registerForm">
                        <b-form class="auth-register-form mt-2">
                            <!-- username -->
                            <b-form-group
                                label="Name"
                                label-for="register-username"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="username"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="register-username"
                                        v-model="form.name"
                                        name="register-username"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        placeholder="John Doe"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- email -->
                            <b-form-group
                                label="Email"
                                label-for="register-email"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="email"
                                    rules="required|email"
                                    mode="lazy"

                                >
                                    <b-form-input
                                        id="register-email"
                                        v-model="form.email"
                                        name="register-email"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        placeholder="john@example.com"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- password -->
                            <b-form-group
                                label-for="register-password"
                                label="Password"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="password"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="
                                            errors.length > 0
                                                ? 'is-invalid'
                                                : null
                                        "
                                    >
                                        <b-form-input
                                            id="register-password"
                                            v-model="form.password"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            name="register-password"
                                            placeholder="············"
                                        />

                                        <b-input-group-append is-text>
                                            <feather-icon
                                                :icon="passwordToggleIcon"
                                                class="cursor-pointer"
                                                @click="
                                                    togglePasswordVisibility
                                                "
                                            />
                                        </b-input-group-append>
                                    </b-input-group>

                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group>
                                <validation-provider
                                    #default="{ errors }"
                                    name="terms and conditions"
                                    rules="checked"
                                >
                                    <b-form-checkbox
                                        id="register-privacy-policy"
                                        v-model="status"
                                        name="checkbox-1"
                                    >
                                        I agreed to
                                        <b-link href="https://enstoncorp.com.sg/terms#accounting-software" target="_blank" class="underline-onhover">terms and conditions.</b-link>
                                    </b-form-checkbox>

                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <ul class="backend-errors p-0 m-0 mb-2">
                                <li
                                    v-for="(errors, index) in backendErrors"
                                    :key="index"
                                    class="
                                        alert alert-danger
                                        m-0
                                        p-1
                                        list-unstyled
                                    "
                                >
                                    <span v-if="Array.isArray(errors)">
                                        <span
                                            v-for="(
                                                error, innerIndex
                                            ) in errors"
                                            :key="innerIndex"
                                        >
                                            {{ error }}
                                        </span>
                                    </span>

                                    <span v-else>
                                        {{ errors }}
                                    </span>
                                </li>
                            </ul>

                            <b-button
                                variant="primary"
                                block
                                type="submit"
                                :disabled="loading"
                                @click.prevent="validationForm"
                            >
                                <span v-if="loading" class="d-flex justify-content-center next-button">
                                    Registering...
                                    <normal-loader class="ml-.5"/>
                                </span>

                                <span v-else>Register</span>
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <span>Already have an account?</span>
                        <br>
                        <b-link :to="{ name: 'login' }" class="mt-.5 d-block" style="text-decoration: underline">
                            <span>Login instead</span>
                        </b-link>
                    </p>

                    <!-- divider -->
                    <div class="divider my-2">
                        <div class="divider-text">or</div>
                    </div>

                    <div class="d-flex justify-content-center">
                        <a
                            type="button"
                            href="#"
                            class="
                                btn btn-lg btn-block btn-white
                                login-with-google-btn
                            "
                            dusk="google-login"
                        >
                            Sign in with Google
                        </a>
                    </div>
                </b-col>
            </b-col>
            <!-- /Register-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { mapActions, mapGetters } from "vuex";
import { successToast } from '@/views/helpers/toast';

extend("checked", data => {
    if (!data) return "You need to accept terms and conditions";
    return true;
});

export default {
    components: {
        BRow,
        BImg,
        BCol,
        BLink,
        BButton,
        BForm,
        BCardTitle,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        // validations
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: false,
            form: {
                name: "",
                email: "",
                password: "",
            },
            // validation
            required,
            email,
            loading: false,
            backendErrors: [],
        };
    },
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
        }),

        passwordToggleIcon() {
            return this.passwordFieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
    },
    methods: {
        ...mapActions({
            signup: "auth/register",
        }),

        validationForm() {
            this.loading = true;
            this.backendErrors = [];

            this.$refs.registerForm.validate().then(success => {
                if (success) {
                    this.submit().catch(e => {
                        let message = e.response
                            ? e.response.data.errors
                            : e.message;
                        if (message === "Network Error") {
                            message = "Web server is down.";
                        } else {
                            message = Object.values(message);
                            message = message.flat();
                        }

                        this.backendErrors.push(message);
                        this.loading = false;
                    });
                } else {
                    this.loading = false;
                }
            });
        },

        async submit() {
            await this.signup(this.form);
            this.$store.commit('auth/SET_AUTHENTICATED', true);
            this.$router.push({name: 'verifyEmail'});

            successToast("We have just sent you a five digit code to your email.");
        },
    },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.login-with-google-btn {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    padding: 0.3rem 0.8rem;
    letter-spacing: 0.5px;
    padding: 0.55rem 1.5rem;
    font-size: 12px;
    transition: all 0.6s;
    background-image: url("/logo/google.svg");
    background-repeat: no-repeat;
    background-position: 20% 50%;
    box-shadow: 0 1px 2px rgb(0 0 0 / 8%), 0 1px 4px rgb(0 0 0 / 12%);
    color: #6d7780;
    display: block;
    width: 100%;
}

.login-with-google-btn:hover {
    border-color: transparent !important;
    background-color: white;
    color: #6d7780;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}
.next-button .preloader {
    left: 50%;
}
</style>
